<template>
	<!-- Billing Information Card -->
	<a-card :bordered="false" class="header-solid h-full" :bodyStyle="{paddingTop: 0, paddingBottom: '16px' }">
		<template #title>
			<h6 class="font-semibold m-0">{{$t('billing.invoices.title')}}</h6>
		</template>
		<template v-if="!fetchGetData">
			<a-card v-for="item in rows" :bordered="false" class="card-billing-info mb-10">
				<a-badge v-if="item.status === STATUS_ACCEPT" status="success" />
				<a-badge v-else-if="item.status === STATUS_CANCEL" status="error" />
				<a-badge v-else status="default" />
				<div class="col-info">
					<a-descriptions :title="`${$t('billing.invoices.id')}: #${item.code} (${item.type == INVOICE_CREATE ? 'Tạo mới' : 'Gia hạn'})`" :column="1">
						<a-descriptions-item :label="$t('billing.invoices.values')">
							{{_.get(item, 'data.resources_label')}}
						</a-descriptions-item>
						<a-descriptions-item :label="$t('billing.invoices.amount')">
							{{currency(item.price)}}
						</a-descriptions-item>
						<a-descriptions-item :label="$t('orders.payment.discountAmount')">
							{{currency(item.discount)}}
						</a-descriptions-item>
						<a-descriptions-item :label="$t('billing.invoices.expired')">
							{{ `${_.get(item, 'data.interval_label')} (${item.start_time} - ${item.end_time})` }}
						</a-descriptions-item>
						<a-descriptions-item :label="$t('billing.invoices.expired')">
							{{item.payment_expired}}
						</a-descriptions-item>
						<a-descriptions-item :label="$t('billing.invoices.created')">
							{{item.created_at}}
						</a-descriptions-item>
					</a-descriptions>
				</div>
				<div class="col-action">
					<a-button v-if="item.status === STATUS_PENDING" type="link" style="display: block;" size="small"  @click="payment(item.id)">
						<svg class="svg-icon" viewBox="0 0 20 20">
							<path fill="none" d="M14.781,14.347h1.738c0.24,0,0.436-0.194,0.436-0.435v-1.739c0-0.239-0.195-0.435-0.436-0.435h-1.738c-0.239,0-0.435,0.195-0.435,0.435v1.739C14.347,14.152,14.542,14.347,14.781,14.347 M18.693,3.045H1.307c-0.48,0-0.869,0.39-0.869,0.869v12.17c0,0.479,0.389,0.869,0.869,0.869h17.387c0.479,0,0.869-0.39,0.869-0.869V3.915C19.562,3.435,19.173,3.045,18.693,3.045 M18.693,16.085H1.307V9.13h17.387V16.085z M18.693,5.653H1.307V3.915h17.387V5.653zM3.48,12.608h7.824c0.24,0,0.435-0.195,0.435-0.436c0-0.239-0.194-0.435-0.435-0.435H3.48c-0.24,0-0.435,0.195-0.435,0.435C3.045,12.413,3.24,12.608,3.48,12.608 M3.48,14.347h6.085c0.24,0,0.435-0.194,0.435-0.435s-0.195-0.435-0.435-0.435H3.48c-0.24,0-0.435,0.194-0.435,0.435S3.24,14.347,3.48,14.347"></path>
						</svg>
						<span class="text-success">{{$t('billing.invoices.btnPayment')}}</span>
					</a-button>
					<!-- <a-button type="link" style="display: block;" size="small">
						<a-icon type="eye" theme="outlined" style="fontSize: 18px;" />
						<span class="text-primary">Xem hóa đơn</span>
					</a-button> -->
				</div>
			</a-card>
		</template>
		<a-spin v-else tip="Loading..."></a-spin>
		<a-pagination v-model:current="currentPage" :total="total" show-less-items @change="onPageChange"/>
	</a-card>
	<!-- / Billing Information Card -->
</template>

<script>
	import getBills from "@/services/bill/getBills"
	import {STATUS_PENDING, STATUS_ACCEPT, STATUS_CANCEL, INVOICE_CREATE} from "@/constant"
	import {currency} from "@/helper"

	export default ({
		data() {
			return {
				currency,
				rows: [],
				fetchGetData: true,
				STATUS_PENDING,
				STATUS_ACCEPT,
				STATUS_CANCEL,
				INVOICE_CREATE,
				currentPage : 1,
				total : 0
			}
		},
		created () {
			this.getData()
		},
		methods: {
			getData() {
				this.fetchGetData = true
				getBills(this.currentPage)
				.then(res => {
					this.fetchGetData = false
					if (res.status == 1) {
						this.rows = res.data.data
						this.total = res.data.total
					}
				})
			},
			payment(id) {
				this.$router.push({name: 'billing.payment', params: {id}})
			},
			onPageChange (page) {
	        	this.currentPage = page
	        	this.getData()
	        },
		}
	})

</script>